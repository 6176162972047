$(document).ready(function () {

    $('.js-access-order').on('click', function () {

        var form = $('.js-access-order-form');
        var error_msg = "";
        var valid = true;
        var button = $(this);

        url = "../php/ajax/shop.php?action=get-access-downloads";

        post_data = form.find('input').serialize();

        //check required fields
        form.checkRequiredFields();

        if (!valid) {
            error_msg += msg;
        }

        if (!button.hasClass('working')) {

            if (valid) {

                button.addClass('working');

                $.post(url, post_data, function (data) {

                    try {
                        response = JSON.parse(data);

                        if (response.success) {

                            $('.js-access-order-form').html(response.html);
                            button.removeClass('working');
                        }
                        else {
                            valid = false;
                            error_msg += response.error_message;
                            msg = error_msg;
                            Apprise(msg);
                            button.removeClass('working');
                        }
                    }
                    catch (e) {
                        valid = false;
                        error_msg += "<li>Technical error: " + data + "</li>";
                        msg = error_msg;
                        Apprise(msg);
                        button.removeClass('working');
                    }

                });
            } else {
                msg = "<h2>Before you continue...</h2>" + error_msg;
                Apprise(msg);
                denotify();
            }
        }
    });

});