$(document).ready(function () {

    $('.js-accept-cookies').on('click', function () {

        var url = "../php/ajax/cookie-popup.php?action=accept-cookies";

        $.post(url, {}, function (data) {
            try {
                var response = JSON.parse(data);
                console.log(response);
                $('.js-cookie-popup').addClass('hidden');
            } catch (e) {
                console.log(e, data);
            }

        });

    });

});