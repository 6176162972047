var style = {
    base: {
        fontFamily: 'Open Sans, sans-serif',
        fontSize: '19px',
        color: "#00aae2",
        fontSmoothing: 'antialiased',

        '::placeholder': {
            color: '#00aae2'
        }
    }
};

var classes = {
    focus: 'focus',
    empty: 'empty',
    invalid: 'invalid'
};

if ($('.js-stripe__card-number').length > 0) { //Only run if fields present

    /*Card Number*/
    var cardNumber = elements.create('cardNumber', {
        style: style,
        classes: classes,
        placeholder: 'Card number'
    });

    cardNumber.mount('.js-stripe__card-number');

    /*Card Expiry*/
    expiry_style = {
        base: {textAlign: 'center'}
    };
    $.extend(true, expiry_style, style);

    var cardExpiry = elements.create('cardExpiry', {
        style: expiry_style,
        classes: classes
    });

    cardExpiry.mount('.js-stripe__card-expiry');

    /*Card CVC*/
    cvc_style = {
        base: {textAlign: 'center'}
    };
    $.extend(true, cvc_style, style);

    var cardCvc = elements.create('cardCvc', {
        style: cvc_style,
        classes: classes
    });

    cardCvc.mount('.js-stripe__card-cvc');

    /*Card type detection & validation */
    cardNumber.addEventListener('change', function (e) {

        $('.checkout__stripe-element.number').attr('data-card_type', e.brand);

        $('.js-card-number-validation').processCardValidation(e);
    });

    /*Expiry validation*/
    cardExpiry.addEventListener('change', function (e) {

        $('.js-card-expiry-validation').processCardValidation(e);

    });

    /*CVC validation*/
    cardCvc.addEventListener('change', function (e) {

        $('.js-card-cvc-validation').processCardValidation(e);

    });

}

$.fn.processCardValidation = function (e) {

    validation_field = $(this);

    if (e.empty) {
        value = '';
        $(this).markCardFieldForAttention();
    }
    else {
        value = 'filled';
        $(this).unmarkCardFieldForAttention();
    }

    if (!e.complete) {
        value = 'incomplete';
        $(this).markCardFieldForAttention();
    }

    if (e.error) {
        value = 'error';
        validation_field.attr('data-error', e.error.message);
        $(this).markCardFieldForAttention();
    } else {
        validation_field.attr('data-error', '');
        $(this).unmarkCardFieldForAttention();
    }

    validation_field.val(value);

};



$.fn.markCardFieldForAttention = function () {

    if ($(this).hasClass('number')) {
        $('label.number').addClass('attention');
    }

    if ($(this).hasClass('expiry')) {
        $('label.expiry').addClass('attention');
    }

    if ($(this).hasClass('cvc')) {
        $('label.cvc').addClass('attention');
    }

};

$.fn.unmarkCardFieldForAttention = function () {

    if ($(this).hasClass('number')) {
        $('label.number').removeClass('attention');
    }

    if ($(this).hasClass('expiry')) {
        $('label.expiry').removeClass('attention');
    }

    if ($(this).hasClass('cvc')) {
        $('label.cvc').removeClass('attention');
    }

};