$(document).ready(function () {

    $('.js-bursary-info').on('click', function (e) {
        e.preventDefault();
        console.log(bursary_info);
        Apprise(bursary_info);
    });

    $('.js-header-basket').on('click', function () {

        location.href = 'basket';

    });

    //Verify emails
    function isValidEmailAddress(emailAddress) {
        var pattern = /^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
        return pattern.test(emailAddress);
    };

    $('body').on('click', '.js-to-checkout', function () {
        location.href = 'checkout';
    });

    $('body').on('change', '.js-basket-quantity', function () {

        quantity = $(this).val();
        product_id = $(this).closest('.basket__product').attr('data-product_id');
        variant_id = $(this).closest('.basket__product').attr('data-variant_id');

        post_data = {
            quantity: quantity,
            product_id: product_id,
            variant_id: variant_id
        };

        updateBasket(post_data);

    });

    $('body').on('click', '.js-add-to-basket', function (e) {

        e.preventDefault();

        button = $(this);

        //validation
        msg = "";
        valid = true;
        error_msg = "";

        if (!button.hasClass('working')) {

            button.addClass('working');

            quantities = $('.js-add-quantity').serialize();

            product_id = $(this).attr('data-product_id');

            post_data = 'product_id=' + product_id;
            post_data += '&' + quantities;

            addToBasket(post_data);
        }

    });

    function updateBasket(post_data) {

        url = "../php/ajax/shop.php?action=update-basket";

        $.post(url, post_data, function (data) {

            try {
                response = JSON.parse(data);

                if (response.success) {

                    $('.js-basket').html(response.basket_html);
                    button.removeClass('working');

                    updateBasketCount();

                    squareUp();
                } else {
                    valid = false;
                    error_msg += response.error_message;
                    msg = error_msg;
                    Apprise(msg);
                    button.removeClass('working');
                }
            } catch (e) {
                valid = false;
                error_msg += "<li>Technical error: " + data + "</li>";
                msg = error_msg;
                Apprise(msg);
                button.removeClass('working');
            }

        });
    }

    function addToBasket(post_data) {

        url = "../php/ajax/shop.php?action=add-to-basket";

        $.post(url, post_data, function (data) {

            try {
                response = JSON.parse(data);

                if (response.success) {

                    $('.js-basket').html(response.basket_html);
                    button.removeClass('working');

                    $('html, body').animate({
                        scrollTop: $('.js-basket').offset().top - $('.global-header').outerHeight() - 40
                    }, 2000);

                    squareUp();

                    updateBasketCount();
                } else {
                    valid = false;
                    error_msg += response.error_message;
                    msg = error_msg;
                    Apprise(msg);
                    button.removeClass('working');
                }
            } catch (e) {
                valid = false;
                error_msg += "<li>Technical error: " + data + "</li>";
                msg = error_msg;
                Apprise(msg);
                button.removeClass('working');
            }

        });
    }

    $('.js-payment-method').on('change ready load', function () {

        $('.js-payment-by').slideUp();
        $('.js-payment-by-' + $(this).val()).slideDown();

    });

    $('.js-billing_address_same').on('change', function () {

        if ($(this).is(':checked')) {
            $('.js-billing-address').hide();
        } else {
            $('.js-billing-address').show();
        }

    });

    $('.js-process-checkout').on('click', function () {

        button = $(this);
        valid = true;
        msg = '';

        if (!button.hasClass('working')) {

            button.addClass('working').text('Please wait...');

            msg = "";
            valid = true;

            //Checks required fields
            $('.required:visible').each(function () {
                if (!$(this).val()) {
                    valid = false;

                    placeholder = $(this).attr('placeholder');
                    msg += "<p>" + placeholder + " is empty</p>";
                    $(this).addClass('attention');
                }
            });

            //Checks card details are valid
            if ($('.js-payment-method').val() == 'card') {

                //Card validation
                $('.required.ghost').each(function () {
                    if (!$(this).val()) {
                        valid = false;

                        placeholder = $(this).attr('placeholder');
                        msg += "<p>" + placeholder + " is empty</p>";
                        $(this).addClass('attention');
                    }
                });

                $('.js-card-field').each(function () {

                    if ($(this).val() == 'incomplete') {
                        valid = false;

                        placeholder = $(this).attr('placeholder');
                        msg += "<p>" + placeholder + " is only partially complete</p>";
                    }

                    if ($(this).val() == 'error') {
                        valid = false;

                        error = $(this).attr('data-error');
                        msg += "<p>" + error + "</p>";

                        $(this).markCardFieldForAttention();
                    }

                });
            }

            if (valid) {
                //Check terms agreed
                if (!$('input[name=agree_terms]').is(':checked')) {
                    valid = false;
                    msg += "<p>You must agree to the terms and conditions before placing your order.</p>"
                }
            }

            if (!isValidEmailAddress($('.js-email').val())) {
                valid = false;
                msg += "<p>Please ensure that your email is formatted correctly.</p>";
            }

            if (!valid) {
                Apprise("<h2>Sorry, there appears to be a few issues with your entries...</h2>" + msg);
                button.removeClass('working').text('Submit');
                if ($('.attention').length > 0) {
                    $('html, body').animate({
                        scrollTop: $('.attention').first().offset().top - $('header').height() - 40
                    }, 200);
                }
            }
            if ($('.js-payment-method').val() == 'card') {
                //Creates Stripe payment method
                if (valid) {

                    console.log('stripe.createPaymentMethod()');

                    if ($('.js-billing_address_same').is(':checked')) {
                        //Use shipping address as billing address
                        name_on_card = $('input[name=name_on_card]').val();
                        address_line1 = $("input[name='customer[address_line_1]']").val();
                        address_zip = $("input[name='customer[postcode]']").val();
                        address_country = 'GB';
                    } else {
                        //Use details from form
                        name_on_card = $('input[name=name_on_card]').val();
                        address_line1 = $("input[name='billing_address[address_line_1]']").val();
                        address_zip = $("input[name='billing_address[postcode]']").val();
                        address_country = 'GB';
                    }

                    stripe.createPaymentMethod('card', cardNumber, {
                        billing_details: {
                            name: name_on_card,
                            /*address_line1: address_line1,
                            address_zip: address_zip,
                            address_country: address_country*/
                            /*TODO: Pass these*/
                        }
                    }).then(function (result) {

                        console.log('stripe.createPaymentMethod() -> then');

                        if (result.error) {
                            valid = false;
                            console.log(result);
                            msg += "Stripe error occurred, unable to create payment method: " + result.error.message;
                            Apprise(msg);
                            $('.js-process-checkout').removeClass('working').text('Submit');
                        } else {

                            //Attempt to confirm payment (check for 3D secure requirements, etc)

                            // Otherwise send paymentMethod.id to your server (see Step 2)
                            fetch('../../php/ajax/stripe--confirm-payment.php', {
                                method: 'POST',
                                headers: {'Content-Type': 'application/json'},
                                body: JSON.stringify({
                                    payment_method_id: result.paymentMethod.id
                                })
                            }).then(function (result) {
                                // Handle server response (see Step 3)
                                result.json().then(function (json) {
                                    handleServerResponse(json);
                                })
                            });
                        }
                    });

                }
            } else {
                finaliseOrder($('.js-payment-method').val());
            }

        }
    })

});


function finaliseOrder(intent_id) {

    console.log('finaliseOrder()');

    msg = $('body').attr('data-msg');
    valid = true;

    if (valid) {
        //Save and create booking
        total_cost = $('.js-order-summary').attr('data-total-cost');
        total_deposit = $('.js-order-summary').attr('data-total-deposit');
        balance_due = $('.js-order-summary').attr('data-balance-due');
        full_payment_due = $('.js-order-summary').attr('data-full-payment-due');

        post_data = $('.js-checkout-form').find('input, select, textarea').serialize();
        post_data += "&intent_id=" + intent_id;

        console.log(post_data);

        url = "../../php/ajax/shop.php?action=create-order";

        $.post(url, post_data, function (data) {

                try {
                    response = JSON.parse(data);

                    if (response.success) {

                        var options = {
                            buttons: {
                                confirm: {
                                    text: 'Continue',
                                    action: function (e) {
                                        Apprise('close');
                                        location.href = "/shop";
                                    }
                                }
                            },

                            override: false
                        };

                        Apprise('<h2>Thank you for your order</h2>' +
                            '<p>Thank you, your order has been submitted successfully, you will receive an email notification once your order is on its way.</p>', options);
                    } else {
                        msg = response.error_list;
                        Apprise(msg);
                        $('button.js-process-checkout').removeClass('working');
                    }
                } catch
                    (e) {
                    msg = '<h2>Sorry, there was a critical error during the purchase process</h2><p>Our sincere apologies for the inconvenience. Please contact us with details of the error as shown below so we can remedy the problem.</p>';
                    msg += e + '<br /><br /> DATA:' + data;
                    Apprise(msg);
                    $('button.js-process-checkout').removeClass('working');
                }

            }
        );
    } else {
        Apprise("<h2>Sorry, there appears to be a few issues with your entries...3</h2>" + msg);
        $('button.js-process-checkout').removeClass('working');
        if ($('.attention').length > 0) {
            $('html, body').animate({
                scrollTop: $('.attention').first().offset().top - $('header').height() - 40
            }, 200);
        }
    }
}

function updateBasketCount() {

    url = "../php/ajax/shop.php?action=get-basket-count";

    $.post(url, post_data, function (data) {

        try {
            response = JSON.parse(data);
            $('.js-basket-count').text(response.count);
        } catch (e) {
            valid = false;
            error_msg += "<li>Technical error: " + data + "</li>";
            msg = error_msg;
            Apprise(msg);
            button.removeClass('working');
        }

    });

}

function handleServerResponse(response) {
    console.log('handleServerResponse()');
    console.log(response);
    if (response.error) {
        Apprise("<h2>An error occurred</h2>" + response.error.message);
        $('.js-process-checkout').removeClass('working').text('Submit');
        // Show error from server on payment form
    } else if (response.requires_action) {
        // Use Stripe.js to handle required card action
        stripe.handleCardAction(
            response.payment_intent_client_secret
        ).then(function (result) {
            console.log(result);
            if (result.error) {
                // Show error in payment form
                Apprise("<h2>An error occurred</h2>" + result.error.message);
                $('.js-process-checkout').removeClass('working').text('Submit');
            } else {
                // The card action has been handled
                // The PaymentIntent can be confirmed again on the server
                fetch('../../php/ajax/stripe--confirm-payment.php', {
                    method: 'POST',
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify({
                        payment_intent_id: result.paymentIntent.id
                    })
                }).then(function (confirmResult) {
                    return confirmResult.json();
                }).then(handleServerResponse);
            }
        });
    } else {
        // Show success message
        finaliseOrder(response.intent_id);
    }
}