$(document).ready(function () {

    $('.js-resource-category-link').on('click', function () {

        id = $(this).attr('data_id');

        category = $('.js-resource-category[data_id=' + id + ']');

        $('html, body').animate({
            scrollTop: category.offset().top - $('.global-header').outerHeight() - 40
        }, 2000);

    });

});