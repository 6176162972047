$(document).ready(function(){
	
	//
	$('textarea').autosize()

	//Send contact form
	$('.contact form button').on('click', function(e){
		
		e.preventDefault();
		
		//validation
		msg = "";
		valid = true;
		error_msg = "";
		
		//
		form = $(this).closest('form');
		
		//check required fields
		form.checkRequiredFields();
		
		if(!valid)
		{
			error_msg += msg;
		}
		
		//
		if(valid)
		{						
			loaderfy();
								
			post_data = form.serializeObject();
			
			url = "../php/ajax/form.php?action=send-enquiry";		
			
			$.post(url, post_data, function(data){
				
				try
				{														
					response = JSON.parse(data);		
																				
					if(response.success)
					{
						deloaderfy();							
						Apprise("<h2>Message sent</h2><p>Thank you for your message. We'll be in touch shortly.</p>");	
						
						form.find('input[type=text], textarea').val('');						
					}
					else
					{
						valid = false;
						error_msg += response.error_message;									
						Apprise('<h2>Sorry, there was a problem with your message...</h2><p>We were unable to send your message at this time. Please remedy the problems outlined below and try again.</p><ul class="delist">' + error_msg + '</ul>');
						deloaderfy();														
					}
				}
				catch(e)
				{
						valid = false;
						error_msg += "<li>Technical error: " + data + "</li>";									
						Apprise('<h2>Oh dear</h2><p>Something went really wrong. Please try again, if you continue to have problems please let us know via phone or email.</p><ul class="delist">' + error_msg + '</ul>');
						deloaderfy();	
				}
													
			});
								
		}
		
		if(!valid)
		{			
			Apprise('<h2>There\'s a problem</h2><p>We were unable to send your message at this time. Please remedy the problems outlined below and try again.</p><ul class="delist">' + error_msg + '</ul>');
			deloaderfy();					
		}
		
	});

	$('form').keypress(function(e) {
		
	    if(e.which == 13)
	    {

	      if( !$(e.target).hasClass('wysiwyg') && !$(e.target).is('textarea') )
	      {	    	
		      $(this).find('button').not('.file button, .files button, .mce-tinymce button').trigger('click');
		      return false;
		  }
		  
	    }
	    
	});	

	$.fn.checkRequiredFields = function(){
	
		form = this;
	
		msg = "";
	
		//check required fields
		form.find('.required, .js-required').each(function(){
			
			placeholder = $(this).closest('label').find('span').text();		
			
			if(placeholder == "")
			{
				placeholder = $(this).attr('placeholder');
			}
			
			if( $(this).is('select') )
			{
				value = $(this).find(':selected').val();
			}
			else
			{
				value = $(this).val();				
			}
			
			if(!value && ((($(this).is(':visible') || $(this).attr('type') == "hidden") && $(this).parent().is(':visible') ) || ($(this).hasClass('autocomplete') && $(this).parent().is(':visible'))) )
			{										
				if($(this).attr('name') == 'files_container')
				{
					wrapper = $(this).closest('div.file');
					
					if(wrapper.find('ul > li').size() == 0)
					{				
				msg += "<li>" + placeholder + " appears to be empty</li>";
			}			
				}
				else
				{				
					msg += "<li>" + placeholder + " appears to be empty</li>";
				}
			}			
			
		});	
		
		if(msg != "")
		{
			valid = false;
		}	
		
		return msg;	
	}
	
	function loaderfy()
	{
		$('.overlay').fadeIn();
	}
	
	function deloaderfy()
	{
		$('.overlay').fadeOut();
	}
	
	
	
	$.fn.serializeObject = function()
	{
	    var o = {};
	    var a = this.serializeArray();
	    $.each(a, function() {
	        if (o[this.name] !== undefined) {
	            if (!o[this.name].push) {
	                o[this.name] = [o[this.name]];
	            }
	            o[this.name].push(this.value || '');
	        } else {
	            o[this.name] = this.value || '';
	        }
	    });
	    return o;
	};		

});