$(document).ready(function () {

    $('.js-send-enquiry-form').on('click', function (e) {
        e.preventDefault();

        button = $(this);
        form = button.closest('form');
        fields = form.find('input, textarea, select');

        //Validation
        msg = "";
        valid = true;

        //Check required fields
        form.checkRequiredFields();

        if (valid) {

            console.log('valid');

            url = "../php/ajax/contact.php?action=send";

            post_data = fields.serialize();

            $.post(url, post_data, function (data) {

                try {
                    response = JSON.parse(data);

                    if (response.success) {
                        console.log('success');
                        Apprise("<h2>Thank you</h2><p>Thank you for your enquiry, we'll be in touch soon.</p>");
                        fields.val('');
                    }
                    else {
                        Apprise(response.error_message);
                    }
                }
                catch (e) {
                    Apprise(e);
                }

            });
        }
        else {
            Apprise("<h2>Sorry, there are a few problems with your entries</h2><ul>" + msg + "</ul>");
        }

    });

});