$(document).ready(function () {

    AOS.init();

    //Make event popups fit height of window
    $(window).on('ready load resize orientationchange', function () {

        buffer = 64;
        h = $(window).height() - (3 * buffer);

        $('.event-popup').css('top', buffer).height(h);

    });

    //Hide mobile nav when you click it's background
    $('body').on('click', function (e) {

        if ($(this).hasClass('mobile-is-open')) {
            if (!$(e.target).is('a') && !$(e.target).hasClass('mobile-header__button')) {
                mobileButton.trigger('click');
            }
        }

    });

    //Don't follow link if it has a no-click class
    $('.no-click > a').on('click', function (e) {

        e.preventDefault();

        return false;

    });

    var modal = $(".js-modal");

    var courseButton = $(".js-courses-link");
    var courses = $(".js-header-courses");

    courseButton.click(function (event) {
        event.preventDefault();
        $(this).toggleClass('is-open');
        courses.toggleClass('is-open');
    });


    var mobileButton = $(".js-mobile-toggle");
    var mobileNav = $(".js-mobile-nav");

    mobileButton.click(function (event) {
        $("body").toggleClass('mobile-is-open');
        mobileNav.toggle();
        modal.toggleClass('is-open');
    });


    var eventButtons = $(".js-event-button");
    var popupButton = $(".js-popup-button");

    eventButtons.click(function (event) {

        event.preventDefault();

        var id = $(this).data("event-id");
        var popup = $("#popup-" + id);
        modal.addClass("is-open");
        popup.show();
        squareUp();
    });

    popupButton.click(function () {
        modal.removeClass("is-open");
        $(this).closest('.event-popup').hide();
    });


});
